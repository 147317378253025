import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import LineNotify from '../../utility/LineNotify';
import AdminData from '../../utility/AdminData';
// Define the interface for DataUser
interface DataUser {
    gritId: string;
    username: string;
    countryCode: string;
    countryName: string;
}

interface BalanceData {
    usdt: string;
    gcredit: string;
    package: string;
    package_value: string;
    roi: string;
}

interface Admin {
    uname: string;
    uid: string;
    created_at: string;
    role: string;
    f_name: string | null;
    m_name: string | null;
    l_name: string | null;
    profile_image: string;
}

export default function StakingPackage() {
    const [searchValue, setSearchValue] = useState('');
    const [selectedOption, setSelectedOption] = useState('package');
    const [dataUser, setDataUser] = useState<DataUser | null>(null);
    const [staking, setStaking] = useState<number | string>('');
    const [PV, setPV] = useState<number | string>('');
    const [balanceData, setBalanceData] = useState<BalanceData | null>(null);
    const [newPackageData, setNewPackageData] = useState({ roi: 0, newMaxPassiveEarnModify: 0, newPackageType: 'MEMBER', newMaxActiveEarn: 0 });
    const [adminData, setAdminData] = useState<Admin | null>(null);
    const token = localStorage.getItem('token');

    const leaderPackage = [
        'WARRIOR', 'WARRIOR V1', 'WARRIOR V2',
        'SUPERVISOR', 'SUPERVISOR V1', 'SUPERVISOR V2', 
        'MANAGER', 'MANAGER V1', 'MANAGER V2'
    ]

    function newPackageCalculate(newMaxActiveEarn: number) {
        let newPackageType = 'MEMBER';
        let roi = 0;
        let newMaxPassiveEarnModify = 0;

        if (newMaxActiveEarn < 100) {
            // MEMBER
            roi = 0;
            newMaxPassiveEarnModify = 0;
            newPackageType = 'MEMBER';
        } else if (newMaxActiveEarn < 1000) {
            // BRONZE
            roi = 1;
            newMaxPassiveEarnModify = 2;
            newPackageType = 'BRONZE';
        } else if (newMaxActiveEarn < 3000) {
            // SILVER
            roi = 1.2;
            newMaxPassiveEarnModify = 2;
            newPackageType = 'SILVER';
        } else if (newMaxActiveEarn < 5000) {
            // GOLD
            roi = 1.4;
            newMaxPassiveEarnModify = 2;
            newPackageType = 'GOLD';
        } else if (newMaxActiveEarn < 10000) {
            // PLATINUM
            roi = 1.6;
            newMaxPassiveEarnModify = 2.5;
            newPackageType = 'PLATINUM';
        } else if (newMaxActiveEarn < 50000) {
            // DIAMOND
            roi = 1.8;
            newMaxPassiveEarnModify = 2.5;
            newPackageType = 'DIAMOND';
        } else if (newMaxActiveEarn < 100000) {
            // VIP
            roi = 2.0;
            newMaxPassiveEarnModify = 3;
            newPackageType = 'VIP';
        } else {
            // VVIP
            roi = 2.2;
            newMaxPassiveEarnModify = 3;
            newPackageType = 'VVIP';
        }
    
        return { roi, newMaxPassiveEarnModify, newPackageType, newMaxActiveEarn };
    }

    function inputValue(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
        const value = e.target.value;
        setSearchValue(value);
    }

    function handleSelection(option: string): void {
        setSelectedOption(option);
    }

    function handlePVChange(e: React.ChangeEvent<HTMLInputElement>): void {
        const value = e.target.value;
        setPV(value === '' ? 0 : parseFloat(value));
    }

    function handleStakingChange(e: React.ChangeEvent<HTMLInputElement>): void {
        if(balanceData?.package_value) {
            const newStakingValue: any = parseInt(e.target.value) + parseInt(balanceData?.package_value);
            setStaking(e.target.value)
            const newPackage = newPackageCalculate(newStakingValue);
            setNewPackageData(newPackage);
        }
    }

    function AdminData(): any {
        const token = localStorage.getItem('token');

        axios.get(`${process.env.REACT_APP_ADMIN_API}/etc/my-profile`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                const data = res.data;
                setAdminData(data);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        AdminData();
    }, []);

    function searchUser() {
        axios
            .get(`${process.env.REACT_APP_ADMIN_API}/manage/fetch-user?uid=${searchValue}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                setDataUser(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function searchBalance() {
        axios
            .get(`${process.env.REACT_APP_ADMIN_API}/manage/fetch-user-wallet?uid=${searchValue}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                setBalanceData(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function handleSearch() {
        setNewPackageData({ roi: 0, newMaxPassiveEarnModify: 0, newPackageType: '-', newMaxActiveEarn: 0 });
        setStaking('');
        setPV('');
        searchUser();
        searchBalance();
    }

    function updatePackage() {
        if(dataUser && newPackageData.newPackageType !== 'MEMBER'){
            Swal.fire({
                title: "Update Package",
                text: `ยืนยันเพื่อ Update Package to ${newPackageData.newPackageType}`,
                showCancelButton: true,
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel'
              }).then((result) => {
                if (result.isConfirmed && dataUser) {
                    axios.post(
                        `${process.env.REACT_APP_MAIN_API}/package/buy-admin`,
                        { 
                            gritId: dataUser?.gritId, 
                            packageValue: newPackageData.newMaxActiveEarn,
                            valueAdded: staking,
                            pv: PV || 0,
                            newMaxPassiveEarn: newPackageData.newMaxPassiveEarnModify,
                            package: newPackageData.newPackageType,
                            roi: selectedOption === '0' ? 0 : newPackageData.roi,
                            adminUsername: adminData?.uname
                        }
                    )
                    .then(() => {
                    Swal.fire({
                        title: 'Update Package สำเร็จ',
                        icon: 'success'
                    });
                
                    setSearchValue('');
                    setDataUser(null);
                    })
                    .catch((err) => {
                    Swal.fire(`${err.message}`, "", "error");
                    console.log(err);
                    });
                }
              });
        }
    }

    function getCountryFlagURL(countryCode: string): string {
        console.log('Country Code', countryCode);
        return `/imgs/flags/${countryCode.toLowerCase()}.svg`;
    }

    return (
        <main className={`w-full h-[1000px]`}>
            <div className="w-full h-auto flex justify-center items-center mt-12 md:mt-0">
                <h1 className="text-3xl">Staking Package | </h1>
                <img className="w-[150px] h-auto" src="/imgs/logo.jpg" alt="brand-logo" />
            </div>

            <div className="flex w-10/12 md:w-8/12 mx-auto mt-4 ">
                <input value={searchValue} type="text" placeholder="ค้นหา GRIT ID" className="h-12 w-full bg-gray-100 outline-0 text-center rounded-xl" onChange={(e) => inputValue(e)} />
                <button className="ml-4 bg-purple-500 text-white rounded-xl px-4" onClick={handleSearch}>
                    Search
                </button>
            </div>

            <div className="w-10/12 md:w-8/12 h-auto flex mt-2.5 mx-auto justify-center items-center">
                <div className="w-full rounded-xl bg-gray-200 h-auto p-2.5  flex items-center justify-center md:h-56">
                    {dataUser && balanceData ? (
                        <div className="flex flex-col justify-center items-center w-full h-full md:flex-row">
                            <div className="w-full h-full  flex flex-col ml-6 text-xl justify-center p-2.5">
                                <p className="text-sm sm:text-xl py-1">GRIT ID: {dataUser.gritId}</p>
                                <p className="text-sm sm:text-xl py-1">Username: {dataUser.username}</p>
                                <p className="text-sm sm:text-xl">
                                    Country: {dataUser.countryName} ({dataUser.countryCode})
                                    <img
                                        src={getCountryFlagURL(dataUser.countryCode)}
                                        alt={`${dataUser.countryCode} flag`}
                                        className="inline-block ml-2"
                                        style={{ width: '40px', height: '40px' }}
                                    />
                                </p>
                            </div>
                            <div className="w-full h-full flex flex-col justify-center items-center text-xl mb-2.5 md:mb-0">
                                {/* <h1 className="mt-3 w-max mx-auto">สถานะการเงิน</h1> */}
                                <p className="w-10/12 mx-auto my-2.5 flex justify-center items-center text-white bg-purple-200 h-12 rounded-xl">{`Package Value: $${parseInt(balanceData.package_value).toLocaleString()}`}</p>
                                <p className="w-10/12 mx-auto bg-purple-200 text-white flex justify-center items-center h-12 rounded-xl">{`ROI: ${balanceData.roi} %`}</p>
                                <p className="w-10/12 mx-auto bg-purple-200 mt-2.5 text-white flex justify-center items-center h-12 rounded-xl">{`Package: ${balanceData.package}`}</p>
                                <p className="w-10/12 mx-auto bg-purple-200 mt-2.5 text-white flex justify-center items-center h-12 rounded-xl">{`New Package: ${newPackageData.newPackageType}`}</p>
                            </div>
                        </div>
                    ) : (
                        <p>No user data found</p>
                    )}
                </div>
            </div>

            {
                balanceData && leaderPackage.includes(balanceData.package) && (
                    <div className="flex w-10/12 md:w-8/12 mx-auto mt-4">
                        <button className="h-12 w-full bg-black text-white rounded-xl" disabled>
                            Unabled to add staking package to Leader account
                        </button>
                    </div>
                    
                )
            }

            {
                balanceData && !leaderPackage.includes(balanceData.package) && (
                    <>
                        <div className="flex w-10/12 md:w-8/12 mx-auto mt-4">
                            <input type="number" placeholder="Enter Staking" className="h-12 w-full mx-1 bg-gray-100 outline-0 text-center rounded-xl" value={staking} onChange={handleStakingChange} />
                            <input type="number" placeholder="Enter PV" className="h-12 w-full mx-1 bg-gray-100 outline-0 text-center rounded-xl" value={PV} onChange={handlePVChange} />
                        </div>

                        <div className="flex w-10/12 md:w-8/12 mx-auto mt-4 gap-x-2.5">
                            <button
                                className={`h-12 w-full outline-0 text-center rounded-xl ${selectedOption === '0' ? 'bg-purple-500 text-white' : 'bg-gray-200'}`}
                                disabled={balanceData.package !== 'MEMBER'}
                                onClick={() => handleSelection('0')}
                            >
                                NO ROI
                            </button>
                            <button
                                className={`h-12 w-full outline-0 text-center rounded-xl ${selectedOption === 'package' ? 'bg-purple-500 text-white' : 'bg-gray-200'}`}
                                onClick={() => handleSelection('package')}
                            >
                                ROI {newPackageData.roi} %
                            </button>
                        </div>

                        <div className="flex w-10/12 md:w-8/12 mx-auto mt-4">
                            <button className="h-12 w-full bg-black text-white rounded-xl" onClick={updatePackage}>
                                Update Package
                            </button>
                        </div>
                    </>
                )
            }

            
        </main>
    );
}
