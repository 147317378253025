import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import AdminData from '../../utility/AdminData';

interface Admin {
    uname: string;
    uid: string;
    created_at: string;
    role: string;
    f_name: string | null;
    m_name: string | null;
    l_name: string | null;
    profile_image: string;
}

export default function SystemConfiguration() {
    const [editState, setEditState] = useState<boolean>(false);
    const [configData, setConfigData] = useState<any>([]);
    const [adminData, setAdminData] = useState<Admin | null>(null);
    const token = localStorage.getItem('token');

    function AdminData(): any {
        const token = localStorage.getItem('token');

        axios.get(`${process.env.REACT_APP_ADMIN_API}/etc/my-profile`, {
            headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
            const data = res.data;
            setAdminData(data);
        })
        .catch((err) => {
            console.log(err);
        });
    }

    useEffect(() => {
        AdminData();
    }, []);
 
    function getSystemConfiguration() {
        axios.get(`${process.env.REACT_APP_ADMIN_API}/system/config`, {
            headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
            if(res.data.length) {
                const configData = {
                    WITHDRAW_TOGGLE: res.data.find((config: any) => config.setting === 'WITHDRAW_TOGGLE')?.value,
                    WITHDRAW_ODD_EVEN_TOGGLE: res.data.find((config: any) => config.setting === 'WITHDRAW_ODD_EVEN_TOGGLE')?.value,
                    WITHDRAW_QUOTA_TOGGLE: res.data.find((config: any) => config.setting === 'WITHDRAW_QUOTA_TOGGLE')?.value,
                    CONVERT_TOGGLE: res.data.find((config: any) => config.setting === 'CONVERT_TOGGLE')?.value,

                    TOPUP_DEFI_PROMOTION_TOGGLE: res.data.find((config: any) => config.setting === 'TOPUP_DEFI_PROMOTION_TOGGLE')?.value,
                    REFERRAL_BONUS: res.data.find((config: any) => config.setting === 'REFERRAL_BONUS')?.value,

                    WITHDRAW_RATIO: res.data.find((config: any) => config.setting === 'WITHDRAW_RATIO')?.value,
                    WITHDRAW_FEE: res.data.find((config: any) => config.setting === 'WITHDRAW_FEE')?.value,
                    WITHDRAW_QUOTA_DAY: res.data.find((config: any) => config.setting === 'WITHDRAW_QUOTA_DAY')?.value,
                    CONVERT_RATIO: res.data.find((config: any) => config.setting === 'CONVERT_RATIO')?.value,

                    TOPUP_DEFI_STAGE1_PERCENT: res.data.find((config: any) => config.setting === 'TOPUP_DEFI_STAGE1_PERCENT')?.value,
                    TOPUP_DEFI_STAGE1_MIN: res.data.find((config: any) => config.setting === 'TOPUP_DEFI_STAGE1_MIN')?.value,
                    TOPUP_DEFI_STAGE1_MAX: res.data.find((config: any) => config.setting === 'TOPUP_DEFI_STAGE1_MAX')?.value,

                    TOPUP_DEFI_STAGE2_PERCENT: res.data.find((config: any) => config.setting === 'TOPUP_DEFI_STAGE2_PERCENT')?.value,
                    TOPUP_DEFI_STAGE2_MIN: res.data.find((config: any) => config.setting === 'TOPUP_DEFI_STAGE2_MIN')?.value,
                    TOPUP_DEFI_STAGE2_MAX: res.data.find((config: any) => config.setting === 'TOPUP_DEFI_STAGE2_MAX')?.value,

                    TOPUP_DEFI_STAGE3_PERCENT: res.data.find((config: any) => config.setting === 'TOPUP_DEFI_STAGE3_PERCENT')?.value,
                    TOPUP_DEFI_STAGE3_MIN: res.data.find((config: any) => config.setting === 'TOPUP_DEFI_STAGE3_MIN')?.value,
                    TOPUP_DEFI_STAGE3_MAX: res.data.find((config: any) => config.setting === 'TOPUP_DEFI_STAGE3_MAX')?.value,

                    TOPUP_DEFI_STAGE4_PERCENT: res.data.find((config: any) => config.setting === 'TOPUP_DEFI_STAGE4_PERCENT')?.value,
                    TOPUP_DEFI_STAGE4_MIN: res.data.find((config: any) => config.setting === 'TOPUP_DEFI_STAGE4_MIN')?.value,
                    TOPUP_DEFI_STAGE4_MAX: res.data.find((config: any) => config.setting === 'TOPUP_DEFI_STAGE4_MAX')?.value,

                    TOPUP_DEFI_STAGE5_PERCENT: res.data.find((config: any) => config.setting === 'TOPUP_DEFI_STAGE5_PERCENT')?.value,
                    TOPUP_DEFI_STAGE5_MIN: res.data.find((config: any) => config.setting === 'TOPUP_DEFI_STAGE5_MIN')?.value,
                    TOPUP_DEFI_STAGE5_MAX: res.data.find((config: any) => config.setting === 'TOPUP_DEFI_STAGE5_MAX')?.value,

                    CONVERT_STAGE1_PROP: res.data.find((config: any) => config.setting === 'CONVERT_STAGE1_PROP')?.value,
                    CONVERT_STAGE1_PERCENT: res.data.find((config: any) => config.setting === 'CONVERT_STAGE1_PERCENT')?.value,

                    CONVERT_STAGE2_PROP: res.data.find((config: any) => config.setting === 'CONVERT_STAGE2_PROP')?.value,
                    CONVERT_STAGE2_PERCENT: res.data.find((config: any) => config.setting === 'CONVERT_STAGE2_PERCENT')?.value,

                    CONVERT_STAGE3_PROP: res.data.find((config: any) => config.setting === 'CONVERT_STAGE3_PROP')?.value,
                    CONVERT_STAGE3_PERCENT: res.data.find((config: any) => config.setting === 'CONVERT_STAGE3_PERCENT')?.value,

                    CONVERT_STAGE4_PROP: res.data.find((config: any) => config.setting === 'CONVERT_STAGE4_PROP')?.value,
                    CONVERT_STAGE4_PERCENT: res.data.find((config: any) => config.setting === 'CONVERT_STAGE4_PERCENT')?.value,

                    CONVERT_STAGE5_PROP: res.data.find((config: any) => config.setting === 'CONVERT_STAGE5_PROP')?.value,
                    CONVERT_STAGE5_PERCENT: res.data.find((config: any) => config.setting === 'CONVERT_STAGE5_PERCENT')?.value,

                    CONVERT_STAGE6_PROP: res.data.find((config: any) => config.setting === 'CONVERT_STAGE6_PROP')?.value,
                    CONVERT_STAGE6_PERCENT: res.data.find((config: any) => config.setting === 'CONVERT_STAGE6_PERCENT')?.value,

                    CONVERT_STAGE7_PROP: res.data.find((config: any) => config.setting === 'CONVERT_STAGE7_PROP')?.value,
                    CONVERT_STAGE7_PERCENT: res.data.find((config: any) => config.setting === 'CONVERT_STAGE7_PERCENT')?.value,

                    CONVERT_STAGE8_PROP: res.data.find((config: any) => config.setting === 'CONVERT_STAGE8_PROP')?.value,
                    CONVERT_STAGE8_PERCENT: res.data.find((config: any) => config.setting === 'CONVERT_STAGE8_PERCENT')?.value,

                    CONVERT_STAGE9_PROP: res.data.find((config: any) => config.setting === 'CONVERT_STAGE9_PROP')?.value,
                    CONVERT_STAGE9_PERCENT: res.data.find((config: any) => config.setting === 'CONVERT_STAGE9_PERCENT')?.value,

                    CONVERT_STAGE10_PROP: res.data.find((config: any) => config.setting === 'CONVERT_STAGE10_PROP')?.value,
                    CONVERT_STAGE10_PERCENT: res.data.find((config: any) => config.setting === 'CONVERT_STAGE10_PERCENT')?.value
                }
                setConfigData(configData);
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }

    useEffect(() => {
        getSystemConfiguration();
    }, []);

    function updateConfiguration() {
        setEditState(!editState);

        if(editState && configData) {
            axios.post(`${process.env.REACT_APP_ADMIN_API}/system/config`,
                {
                    ...configData,
                    adminUsername: adminData?.uname
                },
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            ).then((res) => {
                    Swal.fire({
                        title: 'Update System Configurations สำเร็จ',
                        icon: 'success'
                    });

                    // handleSearch();
                    getSystemConfiguration();
            })
            .catch((err) => {
                console.log(err);
                Swal.fire({
                    title: err.response.data.message,
                    icon: 'error'
                });
                getSystemConfiguration();
            });
        }
    }

    return (
        <main className={`w-full h-[1000px]`}>
            <div className="w-full h-auto flex justify-center items-center mt-12 md:mt-0">
                <h1 className="text-3xl">System Configuration | </h1>
                <img className="w-[150px] h-auto" src="/imgs/logo.jpg" alt="brand-logo" />
            </div>

            <div className="flex flex-row w-10/12 md:w-8/12 mx-auto mt-4 items-center">
                <p className="sm:text-lg text-s w-1/2">Withdrawal system toggle</p>
                <button 
                    className={`h-10 w-1/4 ${parseInt(configData.WITHDRAW_TOGGLE) ? 'bg-green-500' : 'bg-grey-200'} mx-2 border-2 rounded-lg`}
                    onClick={() => setConfigData({ ...configData, WITHDRAW_TOGGLE: '1' })}
                    disabled={!editState}
                >
                    ON
                </button>
                <button 
                    className={`h-10 w-1/4 ${!parseInt(configData.WITHDRAW_TOGGLE) ? 'bg-red-500' : 'bg-grey-200'} ml-2 border-2 rounded-lg`}
                    onClick={() => setConfigData({ ...configData, WITHDRAW_TOGGLE: '0' })}
                    disabled={!editState}
                >
                    OFF
                </button>
            </div>

            <div className="flex flex-row w-10/12 md:w-8/12 mx-auto mt-4 items-center">
                <p className="sm:text-lg text-s w-1/2">Withdrawal ODD EVEN system toggle</p>
                <button 
                    className={`h-10 w-1/4 ${parseInt(configData.WITHDRAW_ODD_EVEN_TOGGLE) ? 'bg-green-500' : 'bg-grey-200'} mx-2 border-2 rounded-lg`}
                    onClick={() => setConfigData({ ...configData, WITHDRAW_ODD_EVEN_TOGGLE: '1' })}
                    disabled={!editState}
                >
                    ON
                </button>
                <button 
                    className={`h-10 w-1/4 ${!parseInt(configData.WITHDRAW_ODD_EVEN_TOGGLE) ? 'bg-red-500' : 'bg-grey-200'} ml-2 border-2 rounded-lg`}
                    onClick={() => setConfigData({ ...configData, WITHDRAW_ODD_EVEN_TOGGLE: '0' })}
                    disabled={!editState}
                >
                    OFF
                </button>
            </div>

            <div className="flex flex-row w-10/12 md:w-8/12 mx-auto mt-4 items-center">
                <p className="sm:text-lg text-s w-1/2">Withdrawal QUOTA system toggle</p>
                <button 
                    className={`h-10 w-1/4 ${parseInt(configData.WITHDRAW_QUOTA_TOGGLE) ? 'bg-green-500' : 'bg-grey-200'} mx-2 border-2 rounded-lg`}
                    onClick={() => setConfigData({ ...configData, WITHDRAW_QUOTA_TOGGLE: '1' })}
                    disabled={!editState}
                >
                    ON
                </button>
                <button 
                    className={`h-10 w-1/4 ${!parseInt(configData.WITHDRAW_QUOTA_TOGGLE) ? 'bg-red-500' : 'bg-grey-200'} ml-2 border-2 rounded-lg`}
                    onClick={() => setConfigData({ ...configData, WITHDRAW_QUOTA_TOGGLE: '0' })}
                    disabled={!editState}
                >
                    OFF
                </button>
            </div>

            <div className="flex flex-row w-10/12 md:w-8/12 mx-auto mt-4 items-center">
                <p className="sm:text-lg text-s w-1/2">Withdrawal USDT Ratio</p>
                <div className="relative h-12 w-1/2">
                    <input 
                        disabled={!editState}
                        type="text"
                        value={configData.WITHDRAW_RATIO}
                        className="h-full w-full pr-16 pl-4 bg-gray-100 outline-0 text-center rounded-xl" 
                        onChange={(e) => setConfigData({ ...configData, WITHDRAW_RATIO: e.target.value })} 
                    />
                    <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                        %
                    </span>
                </div>
            </div>

            <div className="flex flex-row w-10/12 md:w-8/12 mx-auto mt-4 items-center">
                <p className="sm:text-lg text-s w-1/2">Withdrawal Fee</p>
                <div className="relative h-12 w-1/2">
                    <input 
                        disabled={!editState}
                        type="text"
                        value={configData.WITHDRAW_FEE}
                        className="h-full w-full pr-16 pl-4 bg-gray-100 outline-0 text-center rounded-xl" 
                        onChange={(e) => setConfigData({ ...configData, WITHDRAW_FEE: e.target.value })} 
                    />
                    <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                        %
                    </span>
                </div>
            </div>

            <div className="flex flex-row w-10/12 md:w-8/12 mx-auto mt-4 items-center">
                <p className="sm:text-lg text-s w-1/2">Withdrawal QUOTA per day</p>
                <div className="relative h-12 w-1/2">
                    <input 
                        disabled={!editState}
                        type="text"
                        value={configData.WITHDRAW_QUOTA_DAY}
                        className="h-full w-full pr-16 pl-4 bg-gray-100 outline-0 text-center rounded-xl" 
                        onChange={(e) => setConfigData({ ...configData, WITHDRAW_QUOTA_DAY: e.target.value })} 
                    />
                    <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                        $
                    </span>
                </div>
            </div>

            <div className="flex flex-row w-10/12 md:w-8/12 mx-auto mt-12 items-center">
                <p className="sm:text-lg text-s w-1/2">Convert system toggle</p>
                <button 
                    className={`h-10 w-1/4 ${parseInt(configData.CONVERT_TOGGLE) ? 'bg-green-500' : 'bg-grey-200'} mx-2 border-2 rounded-lg`}
                    onClick={() => setConfigData({ ...configData, CONVERT_TOGGLE: '1' })}
                    disabled={!editState}
                >
                    ON
                </button>
                <button 
                    className={`h-10 w-1/4 ${!parseInt(configData.CONVERT_TOGGLE) ? 'bg-red-500' : 'bg-grey-200'} ml-2 border-2 rounded-lg`}
                    onClick={() => setConfigData({ ...configData, CONVERT_TOGGLE: '0' })}
                    disabled={!editState}
                >
                    OFF
                </button>
            </div>

            <div className="flex flex-row w-10/12 md:w-8/12 mx-auto mt-4 items-center">
                <p className="sm:text-lg text-s w-1/2">Convert USDT Ratio</p>
                <div className="relative h-12 w-1/2">
                    <input 
                        disabled={!editState}
                        type="text"
                        value={configData.CONVERT_RATIO}
                        className="h-full w-full pr-16 pl-4 bg-gray-100 outline-0 text-center rounded-xl" 
                        onChange={(e) => setConfigData({ ...configData, CONVERT_RATIO: e.target.value })} 
                    />
                    <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                        %
                    </span>
                </div>
            </div>

            <div>
                <div className="flex flex-row w-10/12 md:w-8/12 mx-auto mt-4 items-center">
                    <p className="sm:text-lg text-s w-1/2 pl-6">Stage 1</p>
                    <div className="relative h-12 w-1/4 mx-2">
                        <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 hidden xl:block">
                            Bonus
                        </span>
                        <input
                            disabled={!editState}
                            type="text"
                            value={configData.CONVERT_STAGE1_PERCENT}
                            className="h-full w-full pr-4 bg-gray-100 outline-0 text-center rounded-xl" 
                            onChange={(e) => setConfigData({ ...configData, CONVERT_STAGE1_PERCENT: e.target.value })} 
                        />
                        <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                            %
                        </span>
                    </div>
                    <div className="relative h-12 w-1/4 ml-2">
                        <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 hidden xl:block">
                            Probability
                        </span>
                        <input 
                            disabled={!editState}
                            type="text"
                            value={configData.CONVERT_STAGE1_PROP}
                            className="h-full w-full pr-4 bg-gray-100 outline-0 text-center rounded-xl" 
                            onChange={(e) => setConfigData({ ...configData, CONVERT_STAGE1_PROP: e.target.value })} 
                        />
                        <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                            x
                        </span>
                    </div>
                </div>

                <div className="flex flex-row w-10/12 md:w-8/12 mx-auto mt-4 items-center">
                    <p className="sm:text-lg text-s w-1/2 pl-6">Stage 2</p>
                    <div className="relative h-12 w-1/4 mx-2">
                        <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 hidden xl:block">
                            Bonus
                        </span>
                        <input
                            disabled={!editState}
                            type="text"
                            value={configData.CONVERT_STAGE2_PERCENT}
                            className="h-full w-full pr-4 bg-gray-100 outline-0 text-center rounded-xl" 
                            onChange={(e) => setConfigData({ ...configData, CONVERT_STAGE2_PERCENT: e.target.value })} 
                        />
                        <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                            %
                        </span>
                    </div>
                    <div className="relative h-12 w-1/4 ml-2">
                        <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 hidden xl:block">
                            Probability
                        </span>
                        <input 
                            disabled={!editState}
                            type="text"
                            value={configData.CONVERT_STAGE2_PROP}
                            className="h-full w-full pr-4 bg-gray-100 outline-0 text-center rounded-xl" 
                            onChange={(e) => setConfigData({ ...configData, CONVERT_STAGE2_PROP: e.target.value })} 
                        />
                        <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                            x
                        </span>
                    </div>
                </div>

                <div className="flex flex-row w-10/12 md:w-8/12 mx-auto mt-4 items-center">
                    <p className="sm:text-lg text-s w-1/2 pl-6">Stage 3</p>
                    <div className="relative h-12 w-1/4 mx-2">
                        <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 hidden xl:block">
                            Bonus
                        </span>
                        <input
                            disabled={!editState}
                            type="text"
                            value={configData.CONVERT_STAGE3_PERCENT}
                            className="h-full w-full pr-4 bg-gray-100 outline-0 text-center rounded-xl" 
                            onChange={(e) => setConfigData({ ...configData, CONVERT_STAGE3_PERCENT: e.target.value })} 
                        />
                        <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                            %
                        </span>
                    </div>
                    <div className="relative h-12 w-1/4 ml-2">
                        <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 hidden xl:block">
                            Probability
                        </span>
                        <input 
                            disabled={!editState}
                            type="text"
                            value={configData.CONVERT_STAGE3_PROP}
                            className="h-full w-full pr-4 bg-gray-100 outline-0 text-center rounded-xl" 
                            onChange={(e) => setConfigData({ ...configData, CONVERT_STAGE3_PROP: e.target.value })} 
                        />
                        <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                            x
                        </span>
                    </div>
                </div>

                <div className="flex flex-row w-10/12 md:w-8/12 mx-auto mt-4 items-center">
                    <p className="sm:text-lg text-s w-1/2 pl-6">Stage 4</p>
                    <div className="relative h-12 w-1/4 mx-2">
                        <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 hidden xl:block">
                            Bonus
                        </span>
                        <input
                            disabled={!editState}
                            type="text"
                            value={configData.CONVERT_STAGE4_PERCENT}
                            className="h-full w-full pr-4 bg-gray-100 outline-0 text-center rounded-xl" 
                            onChange={(e) => setConfigData({ ...configData, CONVERT_STAGE4_PERCENT: e.target.value })} 
                        />
                        <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                            %
                        </span>
                    </div>
                    <div className="relative h-12 w-1/4 ml-2">
                        <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 hidden xl:block">
                            Probability
                        </span>
                        <input 
                            disabled={!editState}
                            type="text"
                            value={configData.CONVERT_STAGE4_PROP}
                            className="h-full w-full pr-4 bg-gray-100 outline-0 text-center rounded-xl" 
                            onChange={(e) => setConfigData({ ...configData, CONVERT_STAGE4_PROP: e.target.value })} 
                        />
                        <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                            x
                        </span>
                    </div>
                </div>

                <div className="flex flex-row w-10/12 md:w-8/12 mx-auto mt-4 items-center">
                    <p className="sm:text-lg text-s w-1/2 pl-6">Stage 5</p>
                    <div className="relative h-12 w-1/4 mx-2">
                        <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 hidden xl:block">
                            Bonus
                        </span>
                        <input
                            disabled={!editState}
                            type="text"
                            value={configData.CONVERT_STAGE5_PERCENT}
                            className="h-full w-full pr-4 bg-gray-100 outline-0 text-center rounded-xl" 
                            onChange={(e) => setConfigData({ ...configData, CONVERT_STAGE5_PERCENT: e.target.value })} 
                        />
                        <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                            %
                        </span>
                    </div>
                    <div className="relative h-12 w-1/4 ml-2">
                        <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 hidden xl:block">
                            Probability
                        </span>
                        <input 
                            disabled={!editState}
                            type="text"
                            value={configData.CONVERT_STAGE5_PROP}
                            className="h-full w-full pr-4 bg-gray-100 outline-0 text-center rounded-xl" 
                            onChange={(e) => setConfigData({ ...configData, CONVERT_STAGE5_PROP: e.target.value })} 
                        />
                        <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                            x
                        </span>
                    </div>
                </div>

                <div className="flex flex-row w-10/12 md:w-8/12 mx-auto mt-4 items-center">
                    <p className="sm:text-lg text-s w-1/2 pl-6">Stage 6</p>
                    <div className="relative h-12 w-1/4 mx-2">
                        <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 hidden xl:block">
                            Bonus
                        </span>
                        <input
                            disabled={!editState}
                            type="text"
                            value={configData.CONVERT_STAGE6_PERCENT}
                            className="h-full w-full pr-4 bg-gray-100 outline-0 text-center rounded-xl" 
                            onChange={(e) => setConfigData({ ...configData, CONVERT_STAGE6_PERCENT: e.target.value })} 
                        />
                        <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                            %
                        </span>
                    </div>
                    <div className="relative h-12 w-1/4 ml-2">
                        <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 hidden xl:block">
                            Probability
                        </span>
                        <input 
                            disabled={!editState}
                            type="text"
                            value={configData.CONVERT_STAGE6_PROP}
                            className="h-full w-full pr-4 bg-gray-100 outline-0 text-center rounded-xl" 
                            onChange={(e) => setConfigData({ ...configData, CONVERT_STAGE6_PROP: e.target.value })} 
                        />
                        <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                            x
                        </span>
                    </div>
                </div>

                <div className="flex flex-row w-10/12 md:w-8/12 mx-auto mt-4 items-center">
                    <p className="sm:text-lg text-s w-1/2 pl-6">Stage 7</p>
                    <div className="relative h-12 w-1/4 mx-2">
                        <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 hidden xl:block">
                            Bonus
                        </span>
                        <input
                            disabled={!editState}
                            type="text"
                            value={configData.CONVERT_STAGE7_PERCENT}
                            className="h-full w-full pr-4 bg-gray-100 outline-0 text-center rounded-xl" 
                            onChange={(e) => setConfigData({ ...configData, CONVERT_STAGE7_PERCENT: e.target.value })} 
                        />
                        <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                            %
                        </span>
                    </div>
                    <div className="relative h-12 w-1/4 ml-2">
                        <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 hidden xl:block">
                            Probability
                        </span>
                        <input 
                            disabled={!editState}
                            type="text"
                            value={configData.CONVERT_STAGE7_PROP}
                            className="h-full w-full pr-4 bg-gray-100 outline-0 text-center rounded-xl" 
                            onChange={(e) => setConfigData({ ...configData, CONVERT_STAGE7_PROP: e.target.value })} 
                        />
                        <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                            x
                        </span>
                    </div>
                </div>

                <div className="flex flex-row w-10/12 md:w-8/12 mx-auto mt-4 items-center">
                    <p className="sm:text-lg text-s w-1/2 pl-6">Stage 8</p>
                    <div className="relative h-12 w-1/4 mx-2">
                        <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 hidden xl:block">
                            Bonus
                        </span>
                        <input
                            disabled={!editState}
                            type="text"
                            value={configData.CONVERT_STAGE8_PERCENT}
                            className="h-full w-full pr-4 bg-gray-100 outline-0 text-center rounded-xl" 
                            onChange={(e) => setConfigData({ ...configData, CONVERT_STAGE8_PERCENT: e.target.value })} 
                        />
                        <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                            %
                        </span>
                    </div>
                    <div className="relative h-12 w-1/4 ml-2">
                        <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 hidden xl:block">
                            Probability
                        </span>
                        <input 
                            disabled={!editState}
                            type="text"
                            value={configData.CONVERT_STAGE8_PROP}
                            className="h-full w-full pr-4 bg-gray-100 outline-0 text-center rounded-xl" 
                            onChange={(e) => setConfigData({ ...configData, CONVERT_STAGE8_PROP: e.target.value })} 
                        />
                        <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                            x
                        </span>
                    </div>
                </div>

                <div className="flex flex-row w-10/12 md:w-8/12 mx-auto mt-4 items-center">
                    <p className="sm:text-lg text-s w-1/2 pl-6">Stage 9</p>
                    <div className="relative h-12 w-1/4 mx-2">
                        <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 hidden xl:block">
                            Bonus
                        </span>
                        <input
                            disabled={!editState}
                            type="text"
                            value={configData.CONVERT_STAGE9_PERCENT}
                            className="h-full w-full pr-4 bg-gray-100 outline-0 text-center rounded-xl" 
                            onChange={(e) => setConfigData({ ...configData, CONVERT_STAGE9_PERCENT: e.target.value })} 
                        />
                        <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                            %
                        </span>
                    </div>
                    <div className="relative h-12 w-1/4 ml-2">
                        <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 hidden xl:block">
                            Probability
                        </span>
                        <input 
                            disabled={!editState}
                            type="text"
                            value={configData.CONVERT_STAGE9_PROP}
                            className="h-full w-full pr-4 bg-gray-100 outline-0 text-center rounded-xl" 
                            onChange={(e) => setConfigData({ ...configData, CONVERT_STAGE9_PROP: e.target.value })} 
                        />
                        <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                            x
                        </span>
                    </div>
                </div>

                <div className="flex flex-row w-10/12 md:w-8/12 mx-auto mt-4 items-center">
                    <p className="sm:text-lg text-s w-1/2 pl-6">Stage 10</p>
                    <div className="relative h-12 w-1/4 mx-2">
                        <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 hidden xl:block">
                            Bonus
                        </span>
                        <input
                            disabled={!editState}
                            type="text"
                            value={configData.CONVERT_STAGE10_PERCENT}
                            className="h-full w-full pr-4 bg-gray-100 outline-0 text-center rounded-xl" 
                            onChange={(e) => setConfigData({ ...configData, CONVERT_STAGE10_PERCENT: e.target.value })} 
                        />
                        <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                            %
                        </span>
                    </div>
                    <div className="relative h-12 w-1/4 ml-2">
                        <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 hidden xl:block">
                            Probability
                        </span>
                        <input 
                            disabled={!editState}
                            type="text"
                            value={configData.CONVERT_STAGE10_PROP}
                            className="h-full w-full pr-4 bg-gray-100 outline-0 text-center rounded-xl" 
                            onChange={(e) => setConfigData({ ...configData, CONVERT_STAGE10_PROP: e.target.value })} 
                        />
                        <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                            x
                        </span>
                    </div>
                </div>

                
            </div>

            <div className="flex flex-row w-10/12 md:w-8/12 mx-auto mt-12 items-center">
                <p className="sm:text-lg text-s w-1/2">DEFI Topup promotion</p>
                <button 
                    className={`h-10 w-1/4 ${parseInt(configData.TOPUP_DEFI_PROMOTION_TOGGLE) ? 'bg-green-500' : 'bg-grey-200'} mx-2 border-2 rounded-lg`}
                    onClick={() => setConfigData({ ...configData, TOPUP_DEFI_PROMOTION_TOGGLE: '1' })}
                    disabled={!editState}
                >
                    ON
                </button>
                <button 
                    className={`h-10 w-1/4 ${!parseInt(configData.TOPUP_DEFI_PROMOTION_TOGGLE) ? 'bg-red-500' : 'bg-grey-200'} ml-2 border-2 rounded-lg`}
                    onClick={() => setConfigData({ ...configData, TOPUP_DEFI_PROMOTION_TOGGLE: '0' })}
                    disabled={!editState}
                >
                    OFF
                </button>
            </div>
 
            { !!parseInt(configData.TOPUP_DEFI_PROMOTION_TOGGLE) && 
            <div>
                <div className="flex flex-row w-10/12 md:w-8/12 mx-auto mt-4 items-center">
                    <p className="sm:text-lg text-s w-1/2 pl-6">Stage 1</p>
                    <div className="relative h-12 w-1/4 mx-2">
                        <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 hidden xl:block">
                            Bonus
                        </span>
                        <input
                            disabled={!editState}
                            type="text"
                            value={configData.TOPUP_DEFI_STAGE1_PERCENT}
                            className="h-full w-full pr-4 bg-gray-100 outline-0 text-center rounded-xl" 
                            onChange={(e) => setConfigData({ ...configData, TOPUP_DEFI_STAGE1_PERCENT: e.target.value })} 
                        />
                        <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                            %
                        </span>
                    </div>
                    <div className="relative h-12 w-1/4 mx-2">
                        <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 hidden xl:block">
                            Min
                        </span>
                        <input
                            disabled={!editState}
                            type="text"
                            value={configData.TOPUP_DEFI_STAGE1_MIN}
                            className="h-full w-full pr-4 bg-gray-100 outline-0 text-center rounded-xl" 
                            onChange={(e) => setConfigData({ ...configData, TOPUP_DEFI_STAGE1_MIN: e.target.value })} 
                        />
                        <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                            $
                        </span>
                    </div>
                    <div className="relative h-12 w-1/4 ml-2">
                        <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 hidden xl:block">
                            Max
                        </span>
                        <input 
                            disabled={!editState}
                            type="text"
                            value={configData.TOPUP_DEFI_STAGE1_MAX}
                            className="h-full w-full pr-4 bg-gray-100 outline-0 text-center rounded-xl" 
                            onChange={(e) => setConfigData({ ...configData, TOPUP_DEFI_STAGE1_MAX: e.target.value })} 
                        />
                        <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                            $
                        </span>
                    </div>
                </div>

                <div className="flex flex-row w-10/12 md:w-8/12 mx-auto mt-4 items-center">
                    <p className="sm:text-lg text-s w-1/2 pl-6">Stage 2</p>
                    <div className="relative h-12 w-1/4 mx-2">
                        <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 hidden xl:block">
                            Bonus
                        </span>
                        <input
                            disabled={!editState}
                            type="text"
                            value={configData.TOPUP_DEFI_STAGE2_PERCENT}
                            className="h-full w-full pr-4 bg-gray-100 outline-0 text-center rounded-xl" 
                            onChange={(e) => setConfigData({ ...configData, TOPUP_DEFI_STAGE2_PERCENT: e.target.value })} 
                        />
                        <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                            %
                        </span>
                    </div>
                    <div className="relative h-12 w-1/4 mx-2">
                        <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 hidden xl:block">
                            Min
                        </span>
                        <input
                            disabled={!editState}
                            type="text"
                            value={configData.TOPUP_DEFI_STAGE2_MIN}
                            className="h-full w-full pr-4 bg-gray-100 outline-0 text-center rounded-xl" 
                            onChange={(e) => setConfigData({ ...configData, TOPUP_DEFI_STAGE2_MIN: e.target.value })} 
                        />
                        <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                            $
                        </span>
                    </div>
                    <div className="relative h-12 w-1/4 ml-2">
                        <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 hidden xl:block">
                            Max
                        </span>
                        <input 
                            disabled={!editState}
                            type="text"
                            value={configData.TOPUP_DEFI_STAGE2_MAX}
                            className="h-full w-full pr-4 bg-gray-100 outline-0 text-center rounded-xl" 
                            onChange={(e) => setConfigData({ ...configData, TOPUP_DEFI_STAGE2_MAX: e.target.value })} 
                        />
                        <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                            $
                        </span>
                    </div>
                </div>

                <div className="flex flex-row w-10/12 md:w-8/12 mx-auto mt-4 items-center">
                    <p className="sm:text-lg text-s w-1/2 pl-6">Stage 3</p>
                    <div className="relative h-12 w-1/4 mx-2">
                        <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 hidden xl:block">
                            Bonus
                        </span>
                        <input
                            disabled={!editState}
                            type="text"
                            value={configData.TOPUP_DEFI_STAGE3_PERCENT}
                            className="h-full w-full pr-4 bg-gray-100 outline-0 text-center rounded-xl" 
                            onChange={(e) => setConfigData({ ...configData, TOPUP_DEFI_STAGE3_PERCENT: e.target.value })} 
                        />
                        <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                            %
                        </span>
                    </div>
                    <div className="relative h-12 w-1/4 mx-2">
                        <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 hidden xl:block">
                            Min
                        </span>
                        <input
                            disabled={!editState}
                            type="text"
                            value={configData.TOPUP_DEFI_STAGE3_MIN}
                            className="h-full w-full pr-4 bg-gray-100 outline-0 text-center rounded-xl" 
                            onChange={(e) => setConfigData({ ...configData, TOPUP_DEFI_STAGE3_MIN: e.target.value })} 
                        />
                        <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                            $
                        </span>
                    </div>
                    <div className="relative h-12 w-1/4 ml-2">
                        <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 hidden xl:block">
                            Max
                        </span>
                        <input 
                            disabled={!editState}
                            type="text"
                            value={configData.TOPUP_DEFI_STAGE3_MAX}
                            className="h-full w-full pr-4 bg-gray-100 outline-0 text-center rounded-xl" 
                            onChange={(e) => setConfigData({ ...configData, TOPUP_DEFI_STAGE3_MAX: e.target.value })} 
                        />
                        <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                            $
                        </span>
                    </div>
                </div>

                <div className="flex flex-row w-10/12 md:w-8/12 mx-auto mt-4 items-center">
                    <p className="sm:text-lg text-s w-1/2 pl-6">Stage 4</p>
                    <div className="relative h-12 w-1/4 mx-2">
                        <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 hidden xl:block">
                            Bonus
                        </span>
                        <input
                            disabled={!editState}
                            type="text"
                            value={configData.TOPUP_DEFI_STAGE4_PERCENT}
                            className="h-full w-full pr-4 bg-gray-100 outline-0 text-center rounded-xl" 
                            onChange={(e) => setConfigData({ ...configData, TOPUP_DEFI_STAGE4_PERCENT: e.target.value })} 
                        />
                        <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                            %
                        </span>
                    </div>
                    <div className="relative h-12 w-1/4 mx-2">
                        <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 hidden xl:block">
                            Min
                        </span>
                        <input
                            disabled={!editState}
                            type="text"
                            value={configData.TOPUP_DEFI_STAGE4_MIN}
                            className="h-full w-full pr-4 bg-gray-100 outline-0 text-center rounded-xl" 
                            onChange={(e) => setConfigData({ ...configData, TOPUP_DEFI_STAGE4_MIN: e.target.value })} 
                        />
                        <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                            $
                        </span>
                    </div>
                    <div className="relative h-12 w-1/4 ml-2">
                        <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 hidden xl:block">
                            Max
                        </span>
                        <input 
                            disabled={!editState}
                            type="text"
                            value={configData.TOPUP_DEFI_STAGE4_MAX}
                            className="h-full w-full pr-4 bg-gray-100 outline-0 text-center rounded-xl" 
                            onChange={(e) => setConfigData({ ...configData, TOPUP_DEFI_STAGE4_MAX: e.target.value })} 
                        />
                        <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                            $
                        </span>
                    </div>
                </div>

                <div className="flex flex-row w-10/12 md:w-8/12 mx-auto mt-4 items-center">
                    <p className="sm:text-lg text-s w-1/2 pl-6">Stage 5</p>
                    <div className="relative h-12 w-1/4 mx-2">
                        <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 hidden xl:block">
                            Bonus
                        </span>
                        <input
                            disabled={!editState}
                            type="text"
                            value={configData.TOPUP_DEFI_STAGE5_PERCENT}
                            className="h-full w-full pr-4 bg-gray-100 outline-0 text-center rounded-xl" 
                            onChange={(e) => setConfigData({ ...configData, TOPUP_DEFI_STAGE5_PERCENT: e.target.value })} 
                        />
                        <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                            %
                        </span>
                    </div>
                    <div className="relative h-12 w-1/4 mx-2">
                        <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 hidden xl:block">
                            Min
                        </span>
                        <input
                            disabled={!editState}
                            type="text"
                            value={configData.TOPUP_DEFI_STAGE5_MIN}
                            className="h-full w-full pr-4 bg-gray-100 outline-0 text-center rounded-xl" 
                            onChange={(e) => setConfigData({ ...configData, TOPUP_DEFI_STAGE5_MIN: e.target.value })} 
                        />
                        <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                            $
                        </span>
                    </div>
                    <div className="relative h-12 w-1/4 ml-2">
                        <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 hidden xl:block">
                            Max
                        </span>
                        <input 
                            disabled={!editState}
                            type="text"
                            value={configData.TOPUP_DEFI_STAGE5_MAX}
                            className="h-full w-full pr-4 bg-gray-100 outline-0 text-center rounded-xl" 
                            onChange={(e) => setConfigData({ ...configData, TOPUP_DEFI_STAGE5_MAX: e.target.value })} 
                        />
                        <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                            $
                        </span>
                    </div>
                </div>
            </div>
            }

            <div className="flex flex-row w-10/12 md:w-8/12 mx-auto mt-4 items-center">
                <p className="sm:text-lg text-s w-1/2">Referral bonus</p>
                <div className="relative h-12 w-1/2">
                    <input 
                        disabled={!editState}
                        type="text"
                        value={configData.REFERRAL_BONUS}
                        className="h-full w-full pr-16 pl-4 bg-gray-100 outline-0 text-center rounded-xl" 
                        onChange={(e) => setConfigData({ ...configData, REFERRAL_BONUS: e.target.value })} 
                    />
                    <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                        %
                    </span>
                </div>
            </div>

            <div className="flex w-10/12 md:w-8/12 mx-auto mt-8 pb-8">
                <button className="h-12 w-full bg-black text-white rounded-xl" onClick={updateConfiguration}>
                    { editState ? 'Save Configurations' : 'Edit Configurations' }
                </button>
            </div>
        </main>
    );
}
