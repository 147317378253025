import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import AdminData from '../../utility/AdminData';

interface Admin {
    uname: string;
    uid: string;
    created_at: string;
    role: string;
    f_name: string | null;
    m_name: string | null;
    l_name: string | null;
    profile_image: string;
}

export default function QueueConfiguration() {
    const [editState, setEditState] = useState<boolean>(false);
    const [configData, setConfigData] = useState<any>([]);
    const [adminData, setAdminData] = useState<Admin | null>(null);
    const token = localStorage.getItem('token');

    function AdminData(): any {
        const token = localStorage.getItem('token');

        axios.get(`${process.env.REACT_APP_ADMIN_API}/etc/my-profile`, {
            headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
            const data = res.data;
            setAdminData(data);
        })
        .catch((err) => {
            console.log(err);
        });
    }

    useEffect(() => {
        AdminData();
    }, []);
 
    function getQueueConfiguration() {
        axios.get(`${process.env.REACT_APP_ADMIN_API}/queue/config`, {
            headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
            if(res.data.length) {
                const configData = {
                    BUY_PERCENT_PER_MONTH: res.data.find((config: any) => config.setting === 'BUY_PERCENT_PER_MONTH')?.value,
                    SELL_PERCENT_PER_TRANS: res.data.find((config: any) => config.setting === 'SELL_PERCENT_PER_TRANS')?.value,
                    TRADING_PORTION_QUEUE: res.data.find((config: any) => config.setting === 'TRADING_PORTION_QUEUE')?.value,
                    MAX_AMOUNT_PER_QUEUE: res.data.find((config: any) => config.setting === 'MAX_AMOUNT_PER_QUEUE')?.value,
                    MIN_AMOUNT_PER_QUEUE: res.data.find((config: any) => config.setting === 'MIN_AMOUNT_PER_QUEUE')?.value,
                    QUEUE_MODE: res.data.find((config: any) => config.setting === 'QUEUE_MODE')?.value,
                    QUEUE_TOGGLE: res.data.find((config: any) => config.setting === 'QUEUE_TOGGLE')?.value,
                    EXCHANGE_TOGGLE: res.data.find((config: any) => config.setting === 'EXCHANGE_TOGGLE')?.value,
                    QUEUE_BUFFER_AMOUNT: res.data.find((config: any) => config.setting === 'QUEUE_BUFFER_AMOUNT')?.value,
                }
                setConfigData(configData);
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }

    useEffect(() => {
        getQueueConfiguration();
    }, []);

    function updateConfiguration() {
        setEditState(!editState);

        console.log('editState: ', editState)
        if(editState && configData) {
            axios.post(`${process.env.REACT_APP_ADMIN_API}/queue/config`,
                {
                    ...configData,
                    adminUsername: adminData?.uname
                },
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            ).then((res) => {
                    Swal.fire({
                        title: 'Update Queue Configurations สำเร็จ',
                        icon: 'success'
                    });

                    // handleSearch();
                    getQueueConfiguration();
            })
            .catch((err) => {
                console.log(err);
                Swal.fire({
                    title: err.response.data.message,
                    icon: 'error'
                });
                getQueueConfiguration();
            });
        }
    }

    return (
        <main className={`w-full h-[1000px]`}>
            <div className="w-full h-auto flex justify-center items-center mt-12 md:mt-0">
                <h1 className="text-3xl">Queue Configuration | </h1>
                <img className="w-[150px] h-auto" src="/imgs/logo.jpg" alt="brand-logo" />
            </div>

            <div className="flex flex-row w-10/12 md:w-8/12 mx-auto mt-4 items-center">
                <p className="sm:text-lg text-s w-1/2">Exchange system toggle</p>
                <button 
                    className={`h-10 w-1/4 ${parseInt(configData.EXCHANGE_TOGGLE) ? 'bg-green-500' : 'bg-grey-200'} mx-2 border-2 rounded-lg`}
                    onClick={() => setConfigData({ ...configData, EXCHANGE_TOGGLE: '1' })}
                    disabled={!editState}
                >
                    ON
                </button>
                <button 
                    className={`h-10 w-1/4 ${!parseInt(configData.EXCHANGE_TOGGLE) ? 'bg-red-500' : 'bg-grey-200'} ml-2 border-2 rounded-lg`}
                    onClick={() => setConfigData({ ...configData, EXCHANGE_TOGGLE: '0' })}
                    disabled={!editState}
                >
                    OFF
                </button>
            </div>

            <div className="flex flex-row w-10/12 md:w-8/12 mx-auto mt-4 items-center">
                <p className="sm:text-lg text-s w-1/2">Queue toggle</p>
                <button 
                    className={`h-10 w-1/4 ${parseInt(configData.QUEUE_TOGGLE) ? 'bg-green-500' : 'bg-grey-200'} mx-2 border-2 rounded-lg`}
                    onClick={() => setConfigData({ ...configData, QUEUE_TOGGLE: '1' })}
                    disabled={!editState}
                >
                    ON
                </button>
                <button 
                    className={`h-10 w-1/4 ${!parseInt(configData.QUEUE_TOGGLE) ? 'bg-red-500' : 'bg-grey-200'} ml-2 border-2 rounded-lg`}
                    onClick={() => setConfigData({ ...configData, QUEUE_TOGGLE: '0' })}
                    disabled={!editState}
                >
                    OFF
                </button>
            </div>

            <div className="flex flex-row w-10/12 md:w-8/12 mx-auto mt-4 items-center">
                <p className="sm:text-lg text-s w-1/2">Queue mode</p>
                <button 
                    className={`h-10 w-1/4 ${configData.QUEUE_MODE === 'DEFAULT' ? 'bg-purple-400' : 'bg-grey-200'} mx-2 border-2 rounded-lg`}
                    onClick={() => setConfigData({ ...configData, QUEUE_MODE: 'DEFAULT' })}
                    disabled={!editState}
                >
                    DEFAULT
                </button>
                <button 
                    className={`h-10 w-1/4 ${configData.QUEUE_MODE === 'RANDOM' ? 'bg-purple-400' : 'bg-grey-200'} ml-2 border-2 rounded-lg`}
                    onClick={() => setConfigData({ ...configData, QUEUE_MODE: 'RANDOM' })}
                    disabled={!editState}
                >
                    RANDOM
                </button>
            </div>

            <div className="flex flex-row w-10/12 md:w-8/12 mx-auto mt-4 items-center">
                <p className="sm:text-lg text-s w-1/2">Random trade amount</p>
                <div className="relative h-12 w-1/4 mx-2">
                    <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 hidden sm:block">
                        Min
                    </span>
                    <input
                        disabled={!editState}
                        type="text"
                        value={configData.MIN_AMOUNT_PER_QUEUE}
                        className="h-full w-full pr-4 bg-gray-100 outline-0 text-center rounded-xl" 
                        onChange={(e) => setConfigData({ ...configData, MIN_AMOUNT_PER_QUEUE: e.target.value })} 
                    />
                    <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                        $
                    </span>
                </div>
                <div className="relative h-12 w-1/4 ml-2">
                    <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 hidden sm:block">
                        Max
                    </span>
                    <input 
                        disabled={!editState}
                        type="text"
                        value={configData.MAX_AMOUNT_PER_QUEUE}
                        className="h-full w-full pr-4 bg-gray-100 outline-0 text-center rounded-xl" 
                        onChange={(e) => setConfigData({ ...configData, MAX_AMOUNT_PER_QUEUE: e.target.value })} 
                    />
                    <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                        $
                    </span>
                </div>
            </div>

            <div className="flex flex-row w-10/12 md:w-8/12 mx-auto mt-4 items-center">
                <p className="sm:text-lg text-s w-1/2">Trading portion</p>
                <div className="relative h-12 w-1/2">
                    <input 
                        disabled={!editState}
                        type="text"
                        value={configData.TRADING_PORTION_QUEUE}
                        className="h-full w-full pr-16 pl-4 bg-gray-100 outline-0 text-center rounded-xl" 
                        onChange={(e) => setConfigData({ ...configData, TRADING_PORTION_QUEUE: e.target.value })} 
                    />
                    <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                        %
                    </span>
                </div>
            </div>

            <div className="flex flex-row w-10/12 md:w-8/12 mx-auto mt-4 items-center">
                <p className="sm:text-lg text-s w-1/2">Buying Quota per month</p>
                <div className="relative h-12 w-1/2">
                    <input 
                        disabled={!editState}
                        type="text"
                        value={configData.BUY_PERCENT_PER_MONTH}
                        className="h-full w-full pr-16 pl-4 bg-gray-100 outline-0 text-center rounded-xl" 
                        onChange={(e) => setConfigData({ ...configData, BUY_PERCENT_PER_MONTH: e.target.value })} 
                    />
                    <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                        %
                    </span>
                </div>
            </div>

            <div className="flex flex-row w-10/12 md:w-8/12 mx-auto mt-4 items-center">
                <p className="sm:text-lg text-s w-1/2">Selling Quota per queue</p>
                <div className="relative h-12 w-1/2">
                    <input 
                        disabled={!editState}
                        type="text"
                        value={configData.SELL_PERCENT_PER_TRANS}
                        className="h-full w-full pr-16 pl-4 bg-gray-100 outline-0 text-center rounded-xl" 
                        onChange={(e) => setConfigData({ ...configData, SELL_PERCENT_PER_TRANS: e.target.value })} 
                    />
                    <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                        %
                    </span>
                </div>
            </div>

            <div className="flex flex-row w-10/12 md:w-8/12 mx-auto mt-4 items-center">
                <p className="sm:text-lg text-s w-1/2">Queue buffer amount</p>
                <div className="relative h-12 w-1/2">
                    <input 
                        disabled={!editState}
                        type="text"
                        value={configData.QUEUE_BUFFER_AMOUNT}
                        className="h-full w-full pr-16 pl-4 bg-gray-100 outline-0 text-center rounded-xl" 
                        onChange={(e) => setConfigData({ ...configData, QUEUE_BUFFER_AMOUNT: e.target.value })} 
                    />
                    <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                        Queue
                    </span>
                </div>
            </div>


            <div className="flex w-10/12 md:w-8/12 mx-auto mt-8">
                <button className="h-12 w-full bg-black text-white rounded-xl" onClick={updateConfiguration}>
                    { editState ? 'Save Configurations' : 'Edit Configurations' }
                </button>
            </div>
        </main>
    );
}
